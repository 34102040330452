import * as dompack from "dompack";


dompack.register(".firstvisit", function()
  {
    if ("visited" in sessionStorage)
      return;

    document.documentElement.classList.add("showfirstvisitpanel");

    let button = document.querySelector(".firstvisit__button");
    button.addEventListener("click", closeFirstVisitPanel);
  });

function closeFirstVisitPanel()
{
  document.documentElement.classList.remove("showfirstvisitpanel");
  sessionStorage.visited = true;
}
