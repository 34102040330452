import * as dompack from "dompack";
import { FormBase, registerHandler } from "@mod-publisher/js/forms";
import { getTid } from "@mod-tollium/js/gettid";
import * as woosearch from "@mod-ipkot/libwoo/js/woosearch";
import "./search.scss";
import "./pagenavigation.scss";

import "./firstvisit.es";
import "./firstvisit.scss";

import * as whintegration from '@mod-system/js/wh/integration';


/*

.searchpage--searching     - RPC call to get new results is in progress
.searchpage--filtersactive - Filters are active
.searchpage--showfilters   - Filters must be expanded
.searchpage--noresults     - Total amount of results is 0

*/


// Default configuration
const defaultSortField = whintegration.config.obj.search_defaultsort; // "title", "publicationdate" or "score"
const defaultOperator = "and";
const resultsPerPage = whintegration.config.obj.search_itemsperpage; // Number of results per page
const contextPages = 2; // Number of pages to show before and after the crrent page in the navigation

const scriptServiceVersion = 2;

const dateFormatter = new Intl.DateTimeFormat("nl", { day: "2-digit", month: "2-digit", year: "numeric" });
const numberFormatter = new Intl.NumberFormat("nl", { style: "decimal" });

let headerNode, resultsContainerNode, searchForm, resultsCountNode, filterTagsContainer;




if (!window._paq)
  window._paq = [];




class SearchForm //extends FormBase
{
  constructor(node)
  {
    this.node = node;
    this.serverpool = new woosearch.ServerPool(whintegration.config.obj.searchurls);

    this.toggleNode = document.body.querySelector(".searchfilters__toggle");
    this.toggleNode.addEventListener("click", event => this._toggleFilters(event))

/*
    console.info({ toggleNode:       this.toggleNode
                 , resultsCountNode: resultsCountNode
                 , filterTagsContainer: filterTagsContainer
                 });
*/
    this.pageNode = document.documentElement; //dompack.closest(this.node, ".searchpage");

    this.curPage = 0;
    this.lastPage = 0;
    this.showAll = false;
    this.sortField = defaultSortField;

    this.readUrlParameters();
    this.refreshFilterValue();
    this.hideFiltersIfFiltersChosen();

    this.doSearch();

    // We don't want to use the default submit handler, so just listen for the submit event and cancel that (with useCapture
    // set to true to prevent the default submit handler from being run)
    this.node.addEventListener("submit", event => this._startSearch(event), true);

    this.node.departments.addEventListener("change", event => this.onDepartmentChange(event));
  }

  refreshFilterValue()
  {
    this.filters = this.getFilterValue();
  }

  clearFilters()
  {
    dompack.changeValue(this.node.text, "");
    dompack.changeValue(this.node.publishedafter, "");
    dompack.changeValue(this.node.publishedbefore, "");
    dompack.changeValue(this.node.departments, "");
    dompack.changeValue(this.node.subjects, "");
    //dompack.changeValue(this.node.ministers, "");
    // dompack.changeValue(this.node.kind, "");
    this.doSearch();
  }

  readUrlParameters()
  {
    const url = new URL(location.href);

    this.showAll = url.searchParams.has("alle-documenten");

    // Simple texts inputs
    if (url.searchParams.has("trefwoord"))
      dompack.changeValue(this.node.text, url.searchParams.get("trefwoord"));

    if (url.searchParams.has("startdatum"))
      dompack.changeValue(this.node.publishedafter, url.searchParams.get("startdatum"));

    if (url.searchParams.has("einddatum"))
      dompack.changeValue(this.node.publishedbefore, url.searchParams.get("einddatum"));

    // Radiobuttons
    if (url.searchParams.has("zoekmodus"))
    {
      const operator = url.searchParams.get("zoekmodus") == "of" ? "or" : "and";
      for (const input of this.node.operator)
        dompack.changeValue(input, operator == input.value);
    }

    // Single-select input, select the option which title is specified
    if (url.searchParams.has("onderdeel"))
    {
      const value = url.searchParams.get("onderdeel");
      const option = Array.from(this.node.departments.options).filter(_ => _.dataset.basetitle == value);
      if (option.length)
        dompack.changeValue(this.node.departments, option[0].value);
      /*
      const values = url.searchParams.getAll("onderdeel");
      for (const option of Array.from(this.node.departments))
        dompack.changeValue(option, values.includes(option.dataset.basetitle));
      */
    }

    if (url.searchParams.has("categorie"))
    {
      console.info("subjects MOET ZIJN", url.searchParams.get("categorie"));
      //this.node.subjects.value = url.searchParams.get("categorie");

      // Find the option matching the tag
      let value = this.getSubjectValueByTag(url.searchParams.get("categorie"));
      dompack.changeValue(this.node.subjects, value);
    }
      // dompack.changeValue(this.node.subjects, url.searchParams.get("subjects"));

/*
    if (url.searchParams.has("bewindspersoon"))
    {
      const value = url.searchParams.get("bewindspersoon");
      const option = Array.from(this.node.ministers.options).filter(_ => _.dataset.basetitle == value);
      if (option.length)
        dompack.changeValue(this.node.ministers, option[0].value);
      /*
      const values = url.searchParams.getAll("bewindspersoon");
      for (const option of Array.from(this.node.ministers))
        dompack.changeValue(option, values.includes(option.dataset.basetitle));
      * /
    }
*/

    /* Subjects are disabled for now
    // Multi-select input, select the options which title is specified
    if (url.searchParams.has("categorie"))
    {
      const values = url.searchParams.getAll("categorie");
      for (const option of Array.from(this.node.subjects))
        dompack.changeValue(option, values.includes(option.dataset.basetitle));
    }
    */

    // Single-select input, select the option which title is specified
    if (url.searchParams.has("doorzoeken"))
    {
      const value = url.searchParams.get("doorzoeken");
      const option = Array.from(this.node.kind.options).filter(_ => _.dataset.basetitle == value);
      if (option.length)
        dompack.changeValue(this.node.kind, option[0].value);
    }

    // Go to the specified page (curPage is 0-based, url param is 1-based)
    if (url.searchParams.has("pagina"))
      this.curPage = (parseInt(url.searchParams.get("pagina")) - 1) || 0;


    // Sort field
    if (url.searchParams.has("sorteer"))
    {
      switch (url.searchParams.get("sorteer"))
      {
        case "publicatiedatum":
          this.sortField = "publicationdate";
          break;
        case "relevantie":
          this.sortField = "score";
          break;
        default:
          this.sortField = defaultSortField;
      }
    }
  }

  updateUrlParameters()
  {
    const url = new URL(location.href);

    // Simple text inputs
    if (this.filters.text != "")
      url.searchParams.set("trefwoord", this.filters.text);
    else
      url.searchParams.delete("trefwoord");

    if (this.filters.operator && this.filters.operator != defaultOperator)
      url.searchParams.set("zoekmodus", "of");
    else
      url.searchParams.delete("zoekmodus");

    if (this.filters.publishedafter != "")
      url.searchParams.set("startdatum", this.filters.publishedafter);
    else
      url.searchParams.delete("startdatum");

    if (this.filters.publishedbefore != "")
      url.searchParams.set("einddatum", this.filters.publishedbefore);
    else
      url.searchParams.delete("einddatum");

    // Single-select input, show the chosen option's title
    url.searchParams.delete("onderdeel");
    for (const option of Array.from(this.node.departments).filter(_ => this.filters.departments.includes(_.value)))
      url.searchParams.append("onderdeel", option.dataset.basetitle);

/*
    url.searchParams.delete("bewindspersoon");
    for (const option of Array.from(this.node.ministers).filter(_ => this.filters.ministers.includes(_.value)))
      url.searchParams.append("bewindspersoon", option.dataset.basetitle);
*/
    // Subjects
    // Multi-select inputs, show the chosen options' titles
    url.searchParams.delete("categorie");
    /*
    for (const option of Array.from(this.node.subjects).filter(_ => this.filters.subjects.includes(_.value)))
      url.searchParams.append("categorie", option.dataset.basetitle);
    */
    for (const option of Array.from(this.node.subjects.options))
    {
      // console.log(option, option.value, this.filters.subjects.indexOf(option.value));
      if (this.filters.subjects.indexOf(option.value) > -1)
        url.searchParams.append("categorie", option.dataset.tag);
    }

    /*
    url.searchParams.delete("doorzoeken");
    for (const option of Array.from(this.node.kind).filter(_ => this.filters.kinds.includes(_.value)))
      url.searchParams.append("doorzoeken", option.dataset.basetitle);
    */

    // For subsequent pages, add the current page (curPage is 0-based, url param is 1-based)
    if (this.curPage > 0)
      url.searchParams.set("pagina", this.curPage + 1);
    else
      url.searchParams.delete("pagina");

    // Sort field
    if (this.sortField != defaultSortField)
    {
      switch (this.sortField)
      {
        case "publicationdate":
          url.searchParams.set("sorteer", "publicatiedatum");
          break;
        case "score":
          url.searchParams.set("sorteer", "relevantie");
          break;
        case "titel":
          url.searchParams.set("sorteer", "titel");
          break;
        default:
          url.searchParams.delete("pagina");
      }
    }
    else
      url.searchParams.delete("pagina");

    // Update url
    history.replaceState(null, "", url.toString());

    return url; //searchstring;
  }

  hideFiltersIfFiltersChosen()
  {
    const visible = !this.areFiltersActive();
    //console.log("Make filters visible", visible);
    this.setFiltersVisible( visible );
  }

  _startSearch(event)
  {
    event.preventDefault();
    dompack.stop(event);

    this.curPage = 0; // reset to start at the first page again

    this.refreshFilterValue();
    this.hideFiltersIfFiltersChosen();

    this.doSearch();
  }

  async gotoPage(newPage, event)
  {
    if (event)
      dompack.stop(event);

    if (newPage >= 0 && newPage <= this.lastPage && newPage != this.curPage)
    {
    }
    else
      return;

    this.curPage = newPage;

    let container = document.querySelector(".resultscontainer");
    container.scrollIntoView();

    await this.doSearch();

    let firstresult = container.querySelector(".searchresult");
    if (firstresult)
      firstresult.focus();
  }

  sortResults(newSortField, event)
  {
    if (event)
      dompack.stop(event);

    if ([ "publicationdate", "score", "title" ].includes(newSortField) && newSortField != this.sortField)
    {
      this.sortField = newSortField;
      this.curPage = 0;
      this.doSearch();
    }
  }




  areFiltersActive()
  {
    // console.info("There are", Object.keys(this.filters).length, "filters active");
    //return Object.keys(this.filters).length > 0;

    for (let fieldname of Object.keys(this.filters))
    {
      let value = this.filters[fieldname];
      // Don't consider the 'operator' filter when checking for active filters (if only the operator is set to 'or', we're
      // not actually searching for anything yet)
      if (fieldname != "operator" && value !== 0 && value !== "" && (!Array.isArray(value) || value.length > 0))
      {
        //console.log("field %s is set to %s", fieldname, value);
        return true;
      }
    }

    return false;
  }

  onDepartmentChange(event)
  {
    if (!this.node.ministers) // If we don't have a filters
      return;

    // Only show the ministers for the chosen department
    const department = this.node.departments.value;
    for (const option of this.node.ministers.options)
    {
      const available = !department || !option.dataset.department || option.dataset.department == department;
      option.hidden = !available;
      option.disabled = !available; // Safari doesn't hide hidden options: https://bugs.webkit.org/show_bug.cgi?id=8351
    }
  }

  getMultiSelectValue(formfieldname)
  {
    let nodelist = this.node[formfieldname];

    if (!nodelist)
    {
      console.error("Missing field", formfieldname);
      return [];
    }

    //console.log(formfieldname, nodelist);

    let values = [];
    for(let node of nodelist)
    {
      if (node.checked)
        values.push(node.value);
    }
    return values;
  }


  getFilterValue()
  {
    let departments = this.node.departments.value ? [ this.node.departments.value ] : [];//this.getMultiSelectValue("departments");
    //let ministers = this.node.ministers.value ? [ this.node.ministers.value ] : [];//this.getMultiSelectValue("ministers");
    //let subjects = this.getMultiSelectValue("subjects");
    //let kinds = this.node.kind.value ? [ this.node.kind.value ] : [];

    let subjects = [];

    let filters =
           { text:            this.node.text.value
           , publishedafter:  this.node.publishedafter.value
           , publishedbefore: this.node.publishedbefore.value
           , departments:     departments
         //, ministers
           , subjects:        this.node.subjects.value != "" ? [ this.node.subjects.value ] : []
         //, kinds
           };

    for (const option of Array.from(this.node.subjects.options))
    {
      //console.log(option, option.value, filters.subjects.indexOf(option.value));
      if (filters.subjects.indexOf(option.value) > -1)
      {
        subjects.push({ value:    option.value
                      , urlparam: option.dataset.tag
                      , title:    option.dataset.basetitle
                      });
      }
    }

    if (this.node.operator)
      filters.operator = this.node.operator.value;


    this.filtersrec = { subjects: subjects };
    return filters;
  }


  createFilter(filterType, filterLabel)
  {
    return (
      <button class="searchfilters__filter"
              onClick={event => this._removeFilter(event, filterType)}
              aria-label={getTid("ipkot:webdesigns.ipkotsite.frontend.js.search.deletefilter", filterLabel)}>
        {filterLabel}
      </button>
    );
  }

  async doSearch(searchoptions)
  {
    if (this.pageNode.classList.contains("searchpage--searching"))
      return; // Already searching

    this.pageNode.classList.add("searchpage--searching");
    resultsContainerNode.setAttribute("aria-busy", "true");

    const query = this.getFilterValue();
    // console.log("getFilterValue", query);
    this.filters = query;

    let urlobj = this.updateUrlParameters();
    let searchstring = urlobj.searchParams.toString();

    console.log("Query", query);

    if (window._paq)
    {
      let urlobj = this.updateUrlParameters();
      let searchstring = urlobj.searchParams.toString();

      console.info("Sending search:", searchstring);
      window._paq.push(['trackEvent', 'searchipkot', 'search', searchstring ]);
    }

    // When showing all matching documents of all matching publications, set first to 0 and count to 10000
    const options = this.showAll ? { first: 0, count: 10000, orderby: this.sortField } : { first: this.curPage * resultsPerPage, count: resultsPerPage, orderby: this.sortField };
    let result;

    if (!this.areFiltersActive() && this.sortField == defaultSortField && this.curPage == 0)
    {
      console.info("Using cached list of recent items");
      result = whintegration.config.obj.initialresults; // the most recent results (with no filters) are cached with the published page
      console.info("Using cached list", result);
    }
    else
    {
      // Initialize parameters
      const queryvars = [];
      if (query.text)
        queryvars.push(`text=${encodeURIComponent(query.text)}`);
      if (query.operator && query.operator != defaultOperator)
        queryvars.push(`operator=${encodeURIComponent(query.operator)}`);
      if (query.publishedafter)
        queryvars.push(`publishedafter=${encodeURIComponent(query.publishedafter)}`);
      if (query.publishedbefore)
        queryvars.push(`publishedbefore=${encodeURIComponent(query.publishedbefore)}`);
      if (query.departments.length)
        queryvars.push(`departments=${query.departments.map(_ => encodeURIComponent(_)).join(",")}`);

/*
      if (query.ministers.length)
        queryvars.push(`ministers=${query.ministers.map(_ => encodeURIComponent(_)).join(",")}`);
*/

      // Categorieen (Subjects)
      if (query.subjects.length)
        queryvars.push(`subjects=${query.subjects.map(_ => encodeURIComponent(_)).join(",")}`);

/*
      if (query.kinds.length)
        queryvars.push(`kinds=${query.kinds.map(_ => encodeURIComponent(_)).join(",")}`);
*/

      queryvars.push(`first=${options.first}`);
      queryvars.push(`count=${options.count}`);
      queryvars.push(`orderby=${options.orderby}`);
      queryvars.push(`v=6`);

      result = await this.serverpool.request("?" + queryvars.join("&"));
      console.info("Got results", result);
    }


    // Generate the new filter tags --------------------------------------------------------------
    filterTagsContainer.innerHTML = "";

    if (query.text)
    {
      filterTagsContainer.append(this.createFilter("text", getTid("ipkot:webdesigns.ipkotsite.frontend.js.search.filter-text", query.text/*, query.operator*/)));
    }

    if (query.publishedafter)
    {
      filterTagsContainer.append(this.createFilter("publishedafter", getTid("ipkot:webdesigns.ipkotsite.frontend.js.search.filter-publishedafter", dateFormatter.format(Date.parse(query.publishedafter)))));
    }

    if (query.publishedbefore)
    {
      filterTagsContainer.append(this.createFilter("publishedbefore", getTid("ipkot:webdesigns.ipkotsite.frontend.js.search.filter-publishedbefore", dateFormatter.format(Date.parse(query.publishedbefore)))));
    }

    if (query.departments.length)
    {
      filterTagsContainer.append(...Array.from(this.node["departments"]).filter(option => option.selected).map(option =>
        this.createFilter("departments", getTid("ipkot:webdesigns.ipkotsite.frontend.js.search.filter-department", option.dataset.basetitle))));
    }

    /*
    if (query.ministers.length)
    {
      filterTagsContainer.append(...Array.from(this.node["ministers"]).filter(option => option.selected).map(option =>
        this.createFilter("ministers", getTid("ipkot:webdesigns.ipkotsite.frontend.js.search.filter-minister", option.dataset.basetitle))));
    }
    */

    if (this.filtersrec.subjects.length)
    {
      filterTagsContainer.append(...this.filtersrec.subjects.map(subject =>
        this.createFilter("subjects", getTid("ipkot:webdesigns.ipkotsite.frontend.js.search.filter-subject", subject.title)/*, option.value*/)));
    }

    /*TODO: Is this still used? - k
    if (query.subjects.length)
    {
      filterTagsContainer.append(...Array.from(this.node["subjects"]).filter(option => option.checked).map(option =>
        this.createFilter("subjects", getTid("ipkot:webdesigns.ipkotsite.frontend.js.search.filter-subject", option.dataset.basetitle), option.value)));
    }
    */

    /*
    if (query.kinds.length)
    {
      filterTagsContainer.append(...Array.from(this.node.kind.options).filter(option => option.selected).map(option =>
        this.createFilter("kind", getTid("ipkot:webdesigns.ipkotsite.frontend.js.search.filter-kind", option.dataset.basetitle))));
    }
    */

    if (filterTagsContainer.children.length > 0)
    {
      let filler = <span class="searchfilters__filler"></span>; // to push the clearbutton to the left

      let clearbutton = <button class="searchfilters__clearbutton" onClick={event => this.clearFilters(event)}>
            {getTid("ipkot:webdesigns.ipkotsite.frontend.js.search.filter-clear")}
          </button>;

      filterTagsContainer.append(filler);
      filterTagsContainer.append(clearbutton);
    }
    // -------------------------------------------------------------------------------------------

    const filtersVisible = this.areFiltersActive();

    document.documentElement.classList.toggle("searchpage--filtersactive", filtersVisible);
    document.documentElement.classList.toggle("searchpage--noresults", result.totalcount == 0);


    dompack.empty(resultsContainerNode);

    if (result.totalcount)
    {
      let newcountnode =
        <div class="resultscount">
          <span class="resultscount__num">{numberFormatter.format(result.totalcount)}</span> {getTid("ipkot:webdesigns.ipkotsite.frontend.js.search.resultcount", result.totalcount, this.areFiltersActive() ? "filtered" : "")}
        </div>;
      resultsCountNode.replaceWith(newcountnode);
      resultsCountNode = newcountnode;

      // Show the sorting options
      resultsContainerNode.append(
        <div class="searchresults__sorting">
          {[
            getTid("ipkot:webdesigns.ipkotsite.frontend.js.search.sortby"),
            <button class={"sortoption" + (this.sortField == "publicationdate" ? " sortoption--current" : "")}
                    aria-label={getTid("ipkot:webdesigns.ipkotsite.frontend.js.search.sortby-publicationdate-aria")}
                  onClick={event => this.sortResults("publicationdate", event)}>
              {getTid("ipkot:webdesigns.ipkotsite.frontend.js.search.sortby-publicationdate")}
            </button>,
            "|",
            <button class={"sortoption" + (this.sortField == "score" ? " sortoption--current" : "")}
                    aria-label={getTid("ipkot:webdesigns.ipkotsite.frontend.js.search.sortby-score-aria")}
                    onClick={event => this.sortResults("score", event)}>
              {getTid("ipkot:webdesigns.ipkotsite.frontend.js.search.sortby-score")}
            </button>,
            "|",
            <button class={"sortoption" + (this.sortField == "title" ? " sortoption--current" : "")}
                    aria-label={getTid("ipkot:webdesigns.ipkotsite.frontend.js.search.sortby-title-aria")}
                    onClick={event => this.sortResults("title", event)}>
              {getTid("ipkot:webdesigns.ipkotsite.frontend.js.search.sortby-title")}
            </button>
          ]}
        </div>
      );


      // FIXME: might be better to publish this in the page
      const rssFeedUrl = document.querySelector(`head > link[rel="alternate"][type="application/rss+xml"]`).getAttribute("href");
      resultsContainerNode.append(
        <div class="searchresults__rssfeed">
          <a href={rssFeedUrl} target="_blank">
            <span class="searchresults__rssfeed-icon"></span>
            {getTid("ipkot:webdesigns.ipkotsite.frontend.js.search.rssfeed")}
          </a>
        </div>
      );



      // Show the results
      //console.info("RESULTS:", result.results);
      const resultsNode = <ol class="searchresults__results" />;
      result.results.forEach((res, idx) =>
      {
        let link = res.link;
        if (link.substr(0, 1) == "#")
          link = whintegration.config.obj.publicationbaseurl + link.substr(1) + "/";

        let descNode, docsNode;
        let metadata = dateFormatter.format(Date.parse(res.publication.publicationdate));
        let subjects = res.publication.subjects ? this.translateSubjects(res.publication.subjects) : [];
        if (subjects.length)
          metadata = subjects.join(", ") + " | " + metadata;

        if (res.publication.decision || res.publication.inventory || res.publication.documents)
        {
          const docs = [ ...(res.publication.decision || []), ...(res.publication.inventory || []), ...(res.publication.documents || []) ];
          const doccount = docs.length;
          // Only show document count if there are results in the documents (multiple_documents is set if there are matches
          // in the complete text of the document)
          if (doccount || res.publication.multiple_documents)
            metadata += ` | ${getTid("ipkot:webdesigns.ipkotsite.frontend.js.search.doccount", doccount)}`;

          if (doccount)
          {
            docsNode =
              <div id={`searchresult__documents--${idx + 1}`} class="searchresult__documents">
                { docs.map(doc =>
                  {
                    let titleNode, contentNode;
                    const docNode =
                      <div class="searchresult__document">
                        <a href={`${link}${doc.url}` + (doc.docpages.length && doc.docpages[0].page > 1 ? `#page=${doc.docpages[0].page}` : "")} class="searchresult__doclink">
                          { titleNode = <h4 class="searchresult__doctitle"></h4> }
                          { contentNode = doc.docpages.length || doc.contents ? <p class="searchresult__docdescription"/> : null }
                          <p class="searchresult__docmetadata">
                            { `${doc.type} | ${ doc.filesize} | ${getTid("ipkot:webdesigns.ipkotsite.frontend.js.search.pagecount", doc.matchpages)}` }
                          </p>
                        </a>
                      </div>
                    titleNode.innerHTML = doc.title;
                    if (contentNode)
                      contentNode.innerHTML = doc.docpages.length ? doc.docpages[0].contents : doc.contents;
                    return docNode;
                  }
                )}
              </div>;
          }
        }

        const resultNode = <li class={"searchresult__item" + (docsNode ? " searchresult__item--hasdocuments" : "")}/>;
        resultNode.append(
          <a href={link}
             class={"searchresult" + (docsNode ? " searchresult--hasdocuments" : "")}
             data-result-id={res.publication.id}
             data-result-score={res.score}>
            <button class="searchresult__toggle"
                    aria-label="Gevonden documenten binnen deze publicatie"
                    aria-controls={`searchresult__documents--${idx + 1}`}
                    onClick={event => this._toggleResultDocuments(event)}
                    tabIndex="0" />
            <h3 class="searchresult__title">{ res.publication.title }</h3>
            { descNode = <p class="searchresult__description" /> }
            <p class="searchresult__metadata">{ metadata }</p>
          </a>
        );
        descNode.innerText = res.publication?.summary ?? '';

        if (docsNode)
          resultNode.append(docsNode);
        resultsNode.append(resultNode);
      });
      resultsContainerNode.append(resultsNode);


      // When showing all results, there is only 1 page
      this.lastPage = this.showAll ? 0 : Math.ceil(result.totalcount / resultsPerPage) - 1;
      if (this.lastPage > 0)
      {
        const pagesNode = <div class="searchresults__pages" />;
        // Add a 'previous' link
        if (this.curPage > 0)
          pagesNode.append(
            <button class="pagelink pagelink--previous"
                    aria-label="Vorige pagina"
                    onClick={event => this.gotoPage(this.curPage - 1, event)}
                    >
            {getTid("ipkot:webdesigns.ipkotsite.frontend.js.search.previouspage")} <span class="assistive">pagina</span>
            </button>
          );
        // Add an ellipsis for left out pages
        if (this.curPage - contextPages > 0)
          pagesNode.append(<span class="pagelink pagelink--ellipsis"></span>);
        // Add context page links
        for (let page = Math.max(0, this.curPage - contextPages); page <= Math.min(this.curPage + contextPages, this.lastPage); ++page)
        {
          if (page == this.curPage)
            pagesNode.append(<span class="pagelink pagelink--page pagelink--current" aria-current="page">{page + 1}</span>);
          else
            pagesNode.append(<button onClick={event => this.gotoPage(page, event)} class="pagelink pagelink--page" aria-label={"Pagina "+(page+1)}>{page + 1}</button>);
        }
        // Add an ellipsis for left out pages
        if (this.curPage + contextPages < this.lastPage)
          pagesNode.append(<span class="pagelink pagelink--ellipsis"></span>);
        // Add a 'next' link
        if (this.curPage < this.lastPage)
          pagesNode.append(
            <button class="pagelink pagelink--next"
                    aria-label="Volgende pagina"
                    onClick={event => this.gotoPage(this.curPage + 1, event)}
                    >
            {getTid("ipkot:webdesigns.ipkotsite.frontend.js.search.nextpage")}
            </button>
          );

        resultsContainerNode.append(pagesNode);
      }
    }
    else
    {
      let newcountnode =
        <div class="resultscount">{getTid("ipkot:webdesigns.ipkotsite.frontend.js.search.resultcount", result.totalcount)}</div>;
      resultsCountNode.replaceWith(newcountnode);
      resultsCountNode = newcountnode;
      //console.log("new count node", resultsCountNode);

      resultsContainerNode.append(<div class="resultscontainer__noresults">{ getTid("ipkot:webdesigns.ipkotsite.frontend.js.search.noresults") }</div>);
    }

    this.pageNode.classList.remove("searchpage--searching");
    resultsContainerNode.setAttribute("aria-busy", "false");
  }

  translateSubjects(subjectguids)
  {
    let subjects = [];
    for (let guid of subjectguids)
    {
      // FIXME: might not be the most performant way to do this
      for (let subject of whintegration.config.obj.subjects)
      {
        if (subject.rowkey == guid)
        {
          subjects.push(subject.title);
          break;
        }
      }
    }
    return subjects;
  }

  getSubjectValueByTag(subjecttag)
  {
    // FIXME: might not be the most performant way to do this
    for (let subject of whintegration.config.obj.subjects)
    {
      if (subject.tag == subjecttag)
        return subject.rowkey;
    }
    return "";
  }



  _toggleResultDocuments(event)
  {
    dompack.stop(event);
    const node = event.target.parentNode;
    if (node.classList.contains("searchresult--hasdocuments"))
    {
      node.classList.toggle("searchresult--showdocuments");
      event.target.setAttribute("aria-expanded", node.classList.contains("searchresult--showdocuments"));
    }
  }

  setFiltersVisible(visible)
  {
    this.pageNode.classList.toggle("searchpage--showfilters", visible);
    this.toggleNode.setAttribute("aria-expanded", visible);

    let form = document.querySelector(".wobsearch__filters__content");
    form.id = "wobsearch__filters__content";
    this.toggleNode.setAttribute("aria-controls", "wobsearch__filters__content")
  }

  _removeFilter(event, field, value)
  {
    dompack.stop(event);

    if (value == undefined)
      dompack.changeValue(this.node[field], "");
    else
      Array.from(this.node[field]).filter(option => option.value == value).forEach(option => option.checked = false);
    this.doSearch();
  }

  _toggleFilters(event)
  {
    dompack.stop(event);
    this.setFiltersVisible(!this.pageNode.classList.contains("searchpage--showfilters"));
  }
}


// DON'T use dompack.register... We want custom fields (such as datepicker picker / multiselect) to be initialized first to prevent them triggering change events
dompack.register(".searchpage", node =>
{
  headerNode = node.querySelector(".resultsheader");
  resultsContainerNode = node.querySelector(".resultscontainer");
  resultsCountNode = document.body.querySelector(".resultscount");
  filterTagsContainer = document.body.querySelector(".wobsearch__filtertags");

  searchForm = new SearchForm(document.getElementById("wobsearchform"));
});
