import * as dompack from "dompack";
//import "./multiselect.scss";

document.addEventListener("click", evt => doCheckForMultiselectClear(evt));

//document.addEventListener("change", evt => doCheckForMultiSelectChange(evt));




function getTitleForCheckbox(elemid)
{
  if (elemid == "")
    return "";

  let labelnode = document.querySelector('label[for="'+elemid+'"]:not(:empty)');
  if (labelnode)
    return labelnode.textContent;
}

function doCheckForMultiSelectChange(evt)
{
  let panel = dompack.closest(evt.target, ".multiselect__panel");
  if (!panel)
    return;

  //window.flup = evt.target;

  let multiselect = dompack.closest(evt.target, ".multiselect");
  updateMultiSelectByNode(multiselect);
}

// update label within the multiselect pulldown to reflect what or how many options are selected
function updateMultiSelectByNode(multiselect)
{
  // console.info("updateMultiSelectByNode", multiselect);
//  let field = targetnode.form[targetnode.name];

  // let multiselect = dompack.closest(targetnode, ".multiselect");
  let valuenode = multiselect.querySelector(".multiselect__valuedisplay__title");

  let cbnodes = multiselect.querySelectorAll('input[type="checkbox"]');

  let titles = [];
  for (let node of cbnodes)
  {
    if (node.checked)
    {
      let title = getTitleForCheckbox(node.id);
      titles.push(title != "" ? title : "??");
    }
  }

  if (titles.length == 0)
    valuenode.textContent = valuenode.getAttribute("data-emptytext");
  else
  {
    let fixedtitle;
    if (titles.length > 1)
      fixedtitle = valuenode.getAttribute("data-multitext");
    else
      fixedtitle = valuenode.getAttribute("data-singletext");

    if (fixedtitle != "")
    {
      fixedtitle = fixedtitle.replace("[amount]", titles.length);
      valuenode.textContent = fixedtitle;
    }
    else
      valuenode.textContent = titles.join(", ");
  }
}

function doCheckForMultiselectClear(evt)
{
  let clearbutton = dompack.closest(evt.target, ".multiselect__panel__clear");
  if (!clearbutton)
    return;

  let panel = dompack.closest(evt.target, ".multiselect__panel");

  // deselect all checkboxes within the multiselect panel
  let cbs = panel.querySelectorAll('input[type="checkbox"]');
  for (let cb of cbs)
    cb.checked = false;

  let multiselect = dompack.closest(evt.target, ".multiselect");
  updateMultiSelectByNode(multiselect);

  let form = dompack.closest(evt.target, "form");
  if (form)
    dompack.dispatchCustomEvent(form, "submit", { bubbles: false, cancelable: true});

  let component = dompack.closest(evt.target, ".multiselect");
  let closetrigger = component.querySelector(".multiselect__closetrigger");
  closetrigger.focus();
}

export function refreshAll()
{
  let multiselects = document.querySelectorAll(".multiselect");
  for (let mscontainer of multiselects)
    updateMultiSelectByNode(mscontainer);
}

dompack.onDomReady(refreshAll);
