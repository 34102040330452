import * as dompack from "dompack";
import "./skiplinks.scss";


dompack.onDomReady(initSkipLinks);

function initSkipLinks()
{
  document.querySelector(".skiplinks").addEventListener("click", doSkipLinks);
}

function doSkipLinks(evt)
{
  evt.preventDefault();

  // NOTE: Safari won't take scroll-margin-top into account yet for .scrollIntoView
  let main = document.querySelector("main");
  main.focus(); // NOTE: Chrome and Safari will scroll the element into view when we focus
  main.scrollIntoView({ behavior: "smooth" }); // Because of Firefox we need to force scrolling (and also to get smooth scrolling)
}
