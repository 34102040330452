import * as dompack from "dompack";
import * as browser from 'dompack/extra/browser';
import * as forms from '@mod-publisher/js/forms';
import { SplitDateField, SplitTimeField } from '@mod-publisher/js/forms/fields/splitdatetime';
//import ImgEditField from '@mod-publisher/js/forms/fields/imgedit';
//import RTDField from '@mod-publisher/js/forms/fields/rtd';
//import UploadField from '@mod-publisher/js/forms/fields/upload';
//import * as googleRecaptcha from "@mod-publisher/js/captcha/google-recaptcha";

//import "../../../shared/multiselect";

// Load neutral styling (optional, but you'll need to supply your own styling for some of the fields below if you skip this)
import '@mod-publisher/js/forms/themes/neutral';

import './buttons.scss';
import './checkbox-radio.scss';
import './textual-input.scss';

import './formlayout.scss';
import './pulldown.scss';
//import './uploadfields.scss';
import './multiselect.scss';
import "./dateinputgroup.scss";


// import $ from "jquery";
// import "jquery-ui/ui/widgets/datepicker";
// import "jquery-ui/ui/i18n/datepicker-nl.js";
// import "jquery-ui/themes/base/datepicker.css";


import $ from "@mod-ipkot/web/jquery-ui/jquery";
import "@mod-ipkot/web/jquery-ui/widgets/autocomplete";
import "@mod-ipkot/web/jquery-ui/widgets/selectmenu";
import "@mod-ipkot/web/jquery-ui/widgets/slider";
import "@mod-ipkot/web/jquery-ui/widgets/datepicker";
import "@mod-ipkot/web/jquery-ui/i18n/datepicker-nl";
import "@mod-ipkot/web/jquery-ui/i18n/datepicker-en";
import "@mod-ipkot/web/jquery-ui-touch-punch";

import "./datepicker.scss";


// Enable forms and our builtin validation
forms.setup({ validate:true });

$.datepicker.setDefaults($.datepicker.regional["nl"]);


// Replaces date fields with a split version
// dompack.register(".wh-form__date", node => new SplitDateField(node));
dompack.register(".wh-form__time", node => new SplitTimeField(node));



dompack.register(".wh-form__date", function(node)
  {
    // iOS and Android have datepickers specialized for touch interaction
    // and small screens, so using our own datepicker is not help to users
    // of those mobile/tablet OS'ses.
    if (browser.getPlatform() == "ios" || browser.getPlatform() == "android")
      return;

    document.documentElement.classList.add("datefieldsreplaced");

    console.log("INIT datepicker");
    $(node).datepicker({ showOn:     "button"
                       , dateFormat: "yy-mm-dd"
                      //, selectOtherMonths: true
                      // , changeMonth: true
                       //, changeYear:  true

                       , changeMonth: !0
                       , changeYear: !0
                       //dateFormat: "dd-mm-yy"
                       , showWeek: !0
                       , showOn: "button"
                       , buttonText: node.dataset.buttontext ? `<span class="assistive">${node.dataset.buttontext}</span>` : ""
                       , weekHeader: "weeknummer"
                       , nextText: "Maand later"
                       , prevText: "Maand eerder"
                       , closeText: "Sluiten"
/*
                       , monthNames: ["Januari", "Februari", "Maart", "April", "Mei", "Juni", "Juli", "Augustus", "September", "Oktober", "November", "December"]
                       , monthNamesShort: ["Jan", "Feb", "Maa", "Apr", "Mei", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dec"]
                       , dayNames: ["Zondag", "Maandag", "Dinsdag", "Woensdag", "Donderdag", "Vrijdag", "Zaterdag"]
                       , dayNamesShort: ["Zon", "Maa", "Din", "Woe", "Don", "Vrij", "Zat"]
                       , dayNamesMin: ["ZO", "MA", "DI", "WO", "DO", "VR", "ZA"]
*/
                       , yearRange: "c-5:c+5"
                       , firstDay: 1

                       //, beforeShow: function() {} // FIX DOM ordering here?
                       //, onSelect:   function(dateStr) { alert(dateStr); }
                       });

    new SplitDateField(node);

    /*
    let button = <div class="wh-form__dateinputgroup__showpicker" aria-label="Kies startdatum uit kalender"></div>;
console.log(button);
    button.addEventListener("click", doShowDatePicker);
    node.parentNode.querySelector(".wh-form__dateinputgroup").appendChild(button);
    */
  });

/*
NOTE: For future use, we might want to replace the button/buttonText of the JQuery UI Datepicker with our own:

function doShowDatePicker(evt)
{
  let originalfield = evt.target.closest(".wh-form__dateinputgroup").querySelector(`input[type="date"]`);

console.log("datepicker", $(originalfield).datepicker);

  $(originalfield).datepicker("show");
window.__ofield = $(originalfield);

  //class="ui-datepicker-inline ui-datepicker ui-widget ui-widget-content ui-helper-clearfix ui-corner-all"
}
*/



/*
// Replaces upload fields with a nicer and edit-supporting version
dompack.register(".wh-form__upload", node => new UploadField(node));

// Enable the imgedit and/or rtd fields:
dompack.register(".wh-form__imgedit", node => new ImgEditField(node));
dompack.register(".wh-form__rtd", node => new RTDField(node));

dompack.register(".canvasupload", node => new CanvasUploadField(node));

// enable the line below AND the googleRecaptcha import if you want to use this recaptcha. you'll also need to enable it in the site profile
// googleRecaptcha.setupGoogleRecaptcha();
*/


/*
// Styled (native) pulldown: add wrapper around select element + arrow for css-styling
dompack.register(".wh-form select", selectnode => {
  let wrappernode = <div class="wh-form__pulldown-styled" />;
  selectnode.parentNode.insertBefore(wrappernode, selectnode);
  wrappernode.appendChild(selectnode);
  wrappernode.appendChild(<span class="arrow fas fa-angle-down" />);
});
*/
