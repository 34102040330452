import * as dompack from 'dompack';
import 'dompack/browserfix/reset.css';

import * as whintegration from '@mod-system/js/wh/integration';
import '@mod-system/js/wh/errorreporting'; //log JS errors to notice log

import "./footer";

import "../shared/multiselect";

import './shared/forms';
import './shared/rtd/rtd';
import './ipkotsite.scss';

import './widgets/video';

import './ipkotsite.lang.json';

import "./pages/search";
import "./pages/publication";


// Behaviour
import "../shared/skiplinks.es";

import "./widgets/accordion";

// open external links in new window - see https://code.webhare.com/publisher/utilities/linkhandler/
import { openLinksInNewWindow } from '@mod-publisher/js/linkhandler';



// open external links in new window
/*
var ourdomain = (new URL(location.href)).host.toLowerCase();
let internalhosts =
    [ ourdomain
    //, "rijksoverheid.nl"
    ];
*/
openLinksInNewWindow({ extensions:    [ 'pdf', 'rss' ]
                    //, internalhosts: internalhosts
                     });



dompack.onDomReady(
  function()
  {
    initHeaderSearchBar();
  });

function initHeaderSearchBar()
{
  let form = document.querySelector("form.header__search");
  if (form)
  {
    document.querySelector(".header__search__submit").addEventListener("click", onSubmit);
    form.addEventListener("focusout", hideSearch);
  }
}

function onSubmit(evt)
{
  // We only want to submit if the search was already activated
  // if (!document.activeElement || !dompack.closest(document.activeElement, "form.header__search"))
  if (!document.documentElement.classList.contains("headersearch--active"))
  {
    evt.preventDefault();
    document.documentElement.classList.add("headersearch--active");
    document.getElementById("header__search__keyword").focus();
    return;
  }
}

function hideSearch(evt)
{
  if (   evt.relatedTarget != null // (if relatedTarget is null nothing was explictly focussed, but <body> probably got focus now)
      && dompack.closest(evt.relatedTarget, "form.header__search"))
    return; // focus is still in the header search

  document.documentElement.classList.remove("headersearch--active");
}
