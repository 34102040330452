/* English (UTF-8) initialisation for the jQuery UI date picker plugin. */
/* Written by Mathias Bynens <http://mathiasbynens.be/> */
import $ from "jquery";
import "../widgets/datepicker";

$.datepicker.regional.en = {
	closeText: "Close",
	prevText: "←",
	nextText: "→",
	currentText: "Today",
	monthNames: [ "january", "february", "march", "april", "may", "june",
	"july", "august", "september", "october", "november", "december" ],
	monthNamesShort: [ "jan", "feb", "mar", "apr", "may", "jun",
	"jul", "aug", "sep", "oct", "nov", "dec" ],
	dayNames: [ "sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday" ],
	dayNamesShort: [ "sun", "mon", "tue", "wed", "thu", "fri", "sat" ],
	dayNamesMin: [ "su", "mo", "tu", "we", "th", "fr", "sa" ],
	weekHeader: "Wk",
	dateFormat: "dd-mm-yy",
	firstDay: 1,
	isRTL: false,
	showMonthAfterYear: false,
	yearSuffix: "" };
$.datepicker.setDefaults( $.datepicker.regional.en );
