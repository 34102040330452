import * as pxl from "@mod-consilio/js/pxl";

const scriptServiceVersion = 2;

export class ServerPool
{
  constructor(baseurls, options)
  {
    this.options = { timeout: 15000, ...options }; //5 sec no longer suffices with the huge ipkot uploads
    this.baseurls = baseurls || [];
    this.initBaseUrl();
  }

  initBaseUrl()
  {
    if (this.baseurls.length)
      this.curbaseurl = this.baseurls[Math.floor(Math.random() * this.baseurls.length)];
    else
      this.curbaseurl = null;
  }

  invalidateBaseUrl(baseurl)
  {
    this.baseurls = this.baseurls.filter(_ => _ != this.curbaseurl);
    this.initBaseUrl();
  }

  async request(subpath, fallback, checkresult)
  {
    const pxldata = {};
    const pathparts = subpath.split("?");
    if (pathparts.length > 0)
    {
      for (const urlvar of pathparts[1].split("&"))
      {
        const name = urlvar.substr(0, urlvar.indexOf("="));
        const value = urlvar.substr(name.length + 1);
        if ([ "first", "count" ].includes(name))
          pxldata[`dn_${name}`] = parseInt(value) || 0;
        else
          pxldata[`ds_${name}`] = value;
      }
    }
    while (true)
    {
      if (this.curbaseurl)
      {
        pxldata.ds_server = this.curbaseurl;
        const url = this.curbaseurl + subpath;
        try
        {
          console.info("Fetching", url);
          const starttime = Date.now();
          let result = await Promise.race([ fetch(url), this.timeoutPromise() ]);
          result = await result.json();

          if (result.v != scriptServiceVersion)
            throw new Error(`Unexpected script service version, got ${result.v}, wanted ${scriptServiceVersion}`);

          pxl.sendPxlEvent("wobcovid19:searchresult",
            { ...pxldata
            , dn_time: Date.now() - starttime
            });
          return result;
        }
        catch (e)
        {
          console.error(`Error while fetching`, e);
          pxl.sendPxlEvent("wobcovid19:searcherror",
            { ...pxldata
            });
          this.invalidateBaseUrl();
        }
      }
      else
      {
        throw new Error("Could not fetch");
      }
    }
  }

  timeoutPromise()
  {
    return new Promise((resolve, reject) => setTimeout(() => reject(new Error(`timeout after ${this.options.timeout}ms`)), this.options.timeout));
  }
}
